<template>
  <div>
    <div class="Section" v-wechat-title="this.clickVal+ '-3A医药城'">
      <div class="breadcrumbNav">
        <span class="navName"><router-link to="/home"><a href="#" style="color:#000">首页 > </a></router-link>   </span>
        <div class="activeNav">
          <span>
            <router-link to="/home"  
            >{{ clickVal }} </router-link>
          </span>
        </div>
      </div>
      <div class="sortBox" v-show="Medicareflag">
        <div class="sortUlbox">
          <div class="ulTitle">
            医保用药
          </div>
          <ul class="sortUl">
            <li
              v-for="(item, index) in MedicareList"
              :key="index"
              @click="MedicareFun(item, index)"
              :class="{ active: Boolean(Medicarecheck === index) }"
            >
              {{ item.name }}
              <!-- 适用于医保 -->
            </li>
          </ul>
        </div>
      </div>
      <div class="sortBox" v-show="OTCflag">
        <div class="sortUlbox">
          <div class="ulTitle">
            非处方药
          </div>
          <ul class="sortUl">
            <li
              v-for="(item, index) in OTCList"
              :key="index"
              @click="OTCFun(item, index)"
              :class="{ active: Boolean(OTCcheck === index) }"
            >
              {{ item.name }}
              <!-- 适用于非处方药 -->
            </li>
          </ul>
        </div>
      </div>
      <ul class="filterUl">
        <li
          v-for="(item, index) in filterItems"
          :key="index"
          @click="filterFun(item,index)"
          :class="{ active: Boolean(filterType === index) }"
        >
          {{ item.sort }}
          <i
            :class="
              item.filterMark == 'price' ? Boolean(item.show === 1)?'active el-icon-caret-top  iconTop':'el-icon-caret-top  iconTop':Boolean(filterType === index)?'active el-icon-bottom':'el-icon-bottom'
            "
            aria-hidden="true"
          ></i>
           <i v-if="item.filterMark == 'price'"
            :class="Boolean(item.show === 2)?'active el-icon-caret-bottom iconBottom':'el-icon-caret-bottom iconBottom'"
            aria-hidden="true"
          ></i>
        </li>
        <!-- <li @click="filterFun('sell')"  :class="{'active':item.show===true}">销量 <i class="el-icon-bottom"></i></li>
        <li @click="filterFun('rise')">上架时间 <i class="el-icon-bottom"></i></li>
        <li @click="filterFun('price')">价格 <i class="el-icon-d-caret"></i></li> -->
        <li>
          <el-checkbox v-model="checked" @change="filterFun()"
            >仅显示有货</el-checkbox
          >
        </li>
      </ul>
      <div class="goodsList" v-if="searchProducts!=[]">
        <div v-if="total==0">
          <p class="NullBox">暂无商品</p>
        </div>
        <div v-else>
                <shopItem
                  v-for="(item, index) in searchProducts"
                  :key="index"
                  class="goods"
                  :info="item"
                ></shopItem>
            <!-- <shopItem
              v-for="(item, index) in searchProducts"
              :key="index"
              class="goods"
              :info="item"
            ></shopItem> -->
            <!-- el-icon-top -->
            <div class="clear"></div>
            <p class="msgDes" @click="handleScroll"><i :class="finished?'':'el-icon-bottom'"></i>{{msgDes}}</p>
        </div>
      </div>
      <div class="goodsList" v-else>
         <p class="NullBox">敬请期待</p>
      </div>
      <!-- <div class="pageBox">
        <el-pagination
          background
          layout="prev, pager, next"
          :hide-on-single-page="false"
          prev-text="上一页"
          next-text="下一页"
          @current-change="handleCurrentChange"
          :current-page="current"
          :page-size="size"
          :total="total"
        >
        </el-pagination>
      </div> -->
    </div>
  </div>
</template>

<script>
const shopItem = () => import("components/index/shopItem.vue");
import {
  // GetNewArrivalPage,GetIdNewArrivalPage,//新品上市
  SearchProducts,
  SearchValProducts,
  getProductByCategory,
  // SearchValProductsAgain
} from "api/product.js";
import Qs from "qs";
export default {
  name: "ProductCategory",
  data() {
    return {
      finished: false,
      total: 0,
      current: 1, //当前页
      size: 10, //每页数量
      seachFlag: false,
      searchVal: "",
      ByGoodsTag:'',
      ByGoodsFlag:false,
      searchProducts: [], //初始商品数据
      clickVal: "", //  面包屑 初始，type类型值
      checked: false, //筛选checked 选中状态  初始为选中
      Medicareflag: false, //医保分类列表  初始值为不展示状态
      Medicarecheck: 0,
      MedicareList: [
        //医保类型
        {
          name: "全国甲类",
          type: {
            medicalInsuranceScope: 0,
            medicalInsuranceType: 1,
          },
        },
        {
          name: "全国乙类",
          type: {
            medicalInsuranceScope: 0,
            medicalInsuranceType: 2,
          },
        },
        // {
        //   name: "部分城市",
        //   type: {
        //     medicalInsuranceScope: 1,
        //   },
        // },
      ],
      OTCflag: false, //医保分类列表  初始值为不展示状态
      OTCcheck: 0,
      OTCList: [
        //非处方药
        {
          name: "OTC甲类",
          type: {
            otcType: 1, //"otcType", value = "OTC标志 0->处方药 1->非处方药"
            drugCategory: 0, //"drugCategory", value = "非处方药类型：0-> 甲级 1->乙级"
          },
        },
        {
          name: "OTC乙类",
          type: {
            otcType: 1,
            drugCategory: 1,
          },
        },
      ],
      filterType:'',
      sort:0,//传给后台的搜索值
      filterItems: [
        { sort: "销量", show: false, filterMark: "sell" },
        { sort: "上架时间", show: false, filterMark: "rise" },
        { sort: "价格",show: 0, filterMark: "price" },
      ],
      parmesData: {}, //初始搜索值传参


      loading: false,
      msgDes:'向下滑动/点击继续浏览商品',
    };
  },
  computed: {},
  created() {
    // console.log([]===[]);
    this.changeType();
  },
  methods: {

    // load () {
    //   this.parmesData.current += 1;
    //   console.log('加载页数', this.parmesData.current)
    //   this.getProducts(this.parmesData);
    // },
    // handleCurrentChange(val) {
    //   this.current = val;
    //   this.parmesData.current = this.current; //当前页
    //   this.parmesData.size = this.size; //每页数量
    //   if (this.parmesData.news) {
    //     // 新品上市接口
    //     this.parmesData.newStatus = true; //新品
    //   } 
    //    this.getProducts(this.parmesData);
    // },
    changeType() {
      if (localStorage.getItem("searchVal")) {
        this.searchVal = localStorage.getItem("searchVal");
      }else{
        if(this.$route.query.seachVal){
           this.searchVal =this.$route.query.seachVal
        }
      }
      if (localStorage.getItem("ByGoodsTag")) {
        this.ByGoodsTag = localStorage.getItem("ByGoodsTag");
      }

      let CategoryType = this.$route.query.type;
      localStorage.setItem("CategoryType", CategoryType);
      switch (Number(CategoryType)) {
        case 1:
          this.clickVal = "医保用药";
          this.Medicareflag = true;
          this.seachFlag = false;
          this.ByGoodsFlag=false;  //佣金标签
          this.OTCflag = false;
          if (sessionStorage.getItem("parmesData")) {
            this.parmesData = Qs.parse(sessionStorage.getItem("parmesData"));
            if (
              Qs.parse(sessionStorage.getItem("parmesData"))
                .medicalInsuranceScope == 1
            ) {
              this.Medicarecheck = 2;
            } else {
              if (
                Qs.parse(sessionStorage.getItem("parmesData"))
                  .medicalInsuranceType == 1
              ) {
                this.Medicarecheck = 0;
              } else {
                this.Medicarecheck = 1;
              }
            }
          } else {
            this.parmesData = {
              //默认全国甲类医保选中
              medicalInsuranceScope: 0, // "medicalInsuranceScope", value = "医保范围:0->全国 1->部分"
              medicalInsuranceType: 1, //"medicalInsuranceType", value = "医保类别:0->非医保 1->甲类医保 2->乙类医保"
            };
          }
          break;
        case 2:
          this.clickVal = "非处方药";
          this.Medicareflag = false;
          this.seachFlag = false;
          this.ByGoodsFlag=false;  //佣金标签
          this.OTCflag = true;
          if (sessionStorage.getItem("parmesData")) {
            this.parmesData = Qs.parse(sessionStorage.getItem("parmesData"));
            if (
              Qs.parse(sessionStorage.getItem("parmesData")).drugCategory == 0
            ) {
              this.OTCcheck = 0;
            } else {
              this.OTCcheck = 1;
            }
          } else {
            this.parmesData = {
              //默认非处方药 甲级
              otcType: 1, //"otcType", value = "OTC标志 0->处方药 1->非处方药"
              drugCategory: 0, //"drugCategory", value = "非处方药类型：0-> 甲级 1->乙级"
            };
          }

          break;
        case 3:
          this.clickVal = "高毛专区";
          this.Medicareflag = false;
          this.OTCflag = false;
          this.seachFlag = false;
          this.ByGoodsFlag=false;  //佣金标签
          this.parmesData = {
            //"isHighGrossProfit", value = "是否为高毛利：0->否 1->是"
            // isHighGrossProfit: 1,
            // isRecentExpiration:0
             tagName: "高毛专区",
          };
          break;
        case 4:
          this.clickVal = "特惠专区";
          this.Medicareflag = false;
          this.OTCflag = false;
          this.seachFlag = false;
          this.ByGoodsFlag=false;  //佣金标签
          this.parmesData = {
            //特惠专区
            tagName: "特惠专区",
          };
          break;
        // case 5:
        //   this.clickVal = "新品预售";
        //   this.Medicareflag = false;
        //   this.OTCflag = false;
        //   this.seachFlag = false;
        //   this.ByGoodsFlag=false;  //佣金标签
        //   this.parmesData = {
        //     //暂无
        //     type: CategoryType,
        //   };
        //   break;
        // case 6:
        //   this.clickVal = "超值套餐";
        //   this.Medicareflag = false;
        //   this.OTCflag = false;
        //   this.seachFlag = false;
        //   this.ByGoodsFlag=false;  //佣金标签
        //   this.parmesData = {
        //     //暂无
        //     type: CategoryType,
        //   };
        //   break;
        case 5:
          this.clickVal = "尾品特卖";
          this.Medicareflag = false;
          this.OTCflag = false;
          this.seachFlag = false;
          this.ByGoodsFlag=false;  //佣金标签
          this.parmesData = {
            //"eventualProduct", value = "尾品特卖标识：0->否 1->是"
             tagName: "尾品特卖",
          };
          break;
         case 6:
          this.clickVal = "保健食品";
          this.Medicareflag = false;
          this.OTCflag = false;
          this.seachFlag = false;
          this.ByGoodsFlag=false;  //佣金标签
          this.parmesData = {
            //保健食品
            tagName: "保健食品",
          };
          break;
        case 7:
          this.clickVal = "积分商城";
          this.Medicareflag = false;
          this.OTCflag = false;
          this.seachFlag = false;
          this.ByGoodsFlag=false;  //佣金标签
          this.parmesData = {
            //暂无
            type: CategoryType,
          };
          break;
        case 10:
          this.clickVal = "新品上市";
          this.Medicareflag = false;
          this.OTCflag = false;
          this.seachFlag = false;
          this.ByGoodsFlag=false;  //佣金标签
          this.parmesData = {
            // news: true,
             tagName: "新品上市",
          };
          break;
          case 12:
          this.clickVal = this.ByGoodsTag;
          this.Medicareflag = false;
          this.OTCflag = false;
          this.seachFlag = false;
          this.ByGoodsFlag=true;  //佣金标签
          this.parmesData = {
            // news: true,
             tagName: this.ByGoodsTag,
          };
          break;
        default:
          this.clickVal = "搜索：" + this.searchVal;
          this.Medicareflag = false;
          this.OTCflag = false;
          this.seachFlag = true; //搜索框输入搜索
          this.ByGoodsFlag=false;  //佣金标签
          this.parmesData = {
            //暂无
            keyword: this.searchVal,
          };
      }
      // let parmesData=JSON.parse(JSON.stringify(this.parmesData));
      this.parmesData.current = 1; //当前页
      this.parmesData.size = 10; //每页数量
        this.parmesData.isHaveStock = 1; //是  显示有库存商品
        this.checked =true
      if (this.parmesData.news) {
        // 新品上市接口
        this.parmesData.newStatus = true; //新品
      } else{
          // this.parmesData.newStatus = false; //新品
      }
      if('type' in  this.parmesData){
        // 根据types搜索
           this.searchProducts = false;
      }else{
        console.log('this.parmesData: ', this.parmesData);
         this.getProducts(this.parmesData);
      }
      
    },
    getProducts(parmesData) {
     
        // 显示loading
     this.$loading({ fullscreen: true ,background:'#ffffff45'})
      if (this.seachFlag) {
        SearchValProducts(parmesData).then((res) => {

          const records = res.data.data.records;
          if(records.length>0){
            // this.searchProducts = records;

            if(this.parmesData.current==1){
                      this.searchProducts=[]
                    }
            records.forEach(item => {
                      this.searchProducts.push(item)
                    });
            this.total = Number(res.data.data.total);
          }else{
             this.searchProducts = [];
             this.total = 0
          }
            // 关闭loading
            this.$loading().close();
          // else{
          //   SearchValProductsAgain(parmesData).then((res) => {
          //     const records = res.data.data.records;
          //     this.searchProducts = records;
          //     this.total = Number(res.data.data.total);
          //       // 关闭loading
          //     this.$loading().close();
          //   }).catch(()=>{
          //     this.$loading().close();
          //   });
          // }
        });
      } else {
        if(this.ByGoodsFlag){
// 佣金标签列表接口
          // console.log('parmesData',parmesData)
          let Datas={
            current:parmesData.current,
            size:parmesData.size,
            categroyId:localStorage.getItem("ByGoodsId")
          }
              getProductByCategory(Datas).then(
                res=>{
                   const records = res.data.data.records;
                    // this.searchProducts = records;
                    if(this.parmesData.current==1){
                      this.searchProducts=[]
                    }
                    records.forEach(item => {
                      this.searchProducts.push(item)
                    });
                    this.total = Number(res.data.data.total);
                      // 关闭loading
                    this.$loading().close();
                }
              )
        }else{
       
           if(parmesData.type!==5 && parmesData.type!==6 && parmesData.type!==9){
            SearchProducts(parmesData).then((res) => {
                const records = res.data.data.records;
                // console.log('请求到的',records.length)
                if(this.parmesData.current==1){
                  this.searchProducts=[]
                }
                // console.log('追加前',this.searchProducts.length)

                records.forEach(item => {
                  this.searchProducts.push(item)
                });
                // console.log('追加后',this.searchProducts.length)
                // this.searchProducts = records;
                this.total = Number(res.data.data.total);
                  // 关闭loading
                this.$loading().close();
              });
            }else{
            
                  // 关闭loading
                this.$loading().close();
            }
          }
        }
      
    },
    // 点击筛选条件，获取商品数据渲染
    filterFun(item,index) {
      if (item) {
          this.filterType=index
          if(this.filterType===2){
            if(item.show===1){
              item.show = 2;
              this.sort=3
            }else{
              item.show = 1;
              this.sort=index
            }
          }else{
            this.filterItems[2].show=0
            this.sort=index
          }
          // 搜索条件 sort：  0 （销售 从高到低）| 1（上架时间  从近到远）| 2 （价格 从低到高 ）| 3（价格 从高到低 ）
            this.parmesData.sort = this.sort; //搜索条件
            this.getProducts(this.parmesData);
      }else {
        //显示有货  isHaveStock   0-> 否,1->是 ", paramType = "query", dataType = "integer"
        if (this.checked == false) {
          this.parmesData.isHaveStock = 0; //否
            this.getProducts(this.parmesData);
        } else {
          //显示有货
           this.parmesData.isHaveStock = 1; //是
            this.getProducts(this.parmesData);
        }
      }
    },

    // 医保类型  点击筛选事件
    MedicareFun(item, index) {
      this.Medicarecheck = index;
      this.parmesData = item.type;
       this.parmesData.current = 1; //当前页
      this.parmesData.size = 10; //每页数量
      this.getProducts(this.parmesData);
    },

    // 非处方药 点击筛选事件
    OTCFun(item, index) {
      this.OTCcheck = index;
      this.parmesData = item.type;
       this.parmesData.current = 1; //当前页
      this.parmesData.size = 10; //每页数量
      this.getProducts(this.parmesData);
    },

    handleScroll() {  
        this.loading = true
        this.finished = false
        this.msgDes="点击继续浏览商品"
        console.log('当前页数',this.parmesData.current)
        console.log('this.total',this.total)
        console.log('总页数',parseInt(this.total/10))
        if (this.parmesData.current <= parseInt(this.total/10)) {
            this.parmesData.current += 1;// 页数+1
            console.log('加载页数', this.parmesData.current)
            console.log(this.parmesData)
            // 重新加载数据
            this.getProducts(this.parmesData);
        }
        // 数据全部加载完成
        if (this.parmesData.current > parseInt(this.total/10)) {

          this.loading = false
          this.finished = true
          if(this.total!==0){
            setTimeout(() => {
              this.msgDes="我也是有底线的"
            }, 1000);
          }
        }
      
    }  
  },
  watch: {
    "$route.query": "changeType",
  },
  components: {
    shopItem,
  },

  mounted() {

   
    const _this = this
    document.body.onscroll = function () {
      // 变量scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      // 变量windowHeight是可视区的高度
      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight
      // 变量scrollHeight是滚动条的总高度
      var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight

      _this.finished = false

      // 滚动条到底部的条件
      if (scrollTop + windowHeight === scrollHeight) {
        _this.loading = true
        _this.msgDes="向下滑动继续浏览商品"
        console.log('_this.page',_this.parmesData.current)
        console.log('_this.total',parseInt(_this.total/10))
        if (_this.parmesData.current <= parseInt(_this.total/10)) {
            _this.parmesData.current += 1;// 页数+1
            // console.log('加载页数', _this.parmesData.current)
            // 重新加载数剧
            _this.getProducts(_this.parmesData);
        }
        // 数据全部加载完成

        if (_this.parmesData.current > parseInt(_this.total/10)) {
          _this.loading = false
          _this.finished = true
          if(_this.total!==0){
            setTimeout(() => {
              _this.msgDes="我也是有底线的"
            }, 1000);
          }
        }
      }
    }


  },
  updated() {},
};
</script>
<style>
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #ed3129;
  color: #fff;
}
/* 上一页，下一页样式 */
.el-pagination button,
.el-pagination span:not([class*="suffix"]) {
  background: #fff;
  color: #666;
  padding: 2px;
  box-sizing: border-box;
}

/* 每个页码的样式 */
.el-pagination.is-background .el-pager li {
  background: #fff;
  color: #666;
  padding: px;
  box-sizing: border-box;
}
.el-pagination.is-background .el-pager li:not(.disabled):not(.active):hover {
  color: #ed3129;
  font-size: 14px;
}
</style>
<style lang="less" scoped>
.pageBox {
  float: left;
  width: 100%;
  .el-pagination {
    padding-top: 40px;
    text-align: center;
    float: right;
  }
}

@import "~style/index.less";
/* 隐藏未编译的变量 */
[v-cloak] {
  display: none;
}
.Section {
  width: 100%;
  float: left;
  margin-top: 10px;
  background: #f6f6f6;
  padding-bottom: 40px;
  overflow: hidden;
  .breadcrumbNav {
    padding: 8px 15px 15px 0;
    margin-bottom: 17px;
    font-size: 13px;
    line-height: 20px;
    .navName {
      float: left;
      color: #999;
      padding-right: 8px;
    }
    .activeNav {
      float: left;
      color: #999;
      line-height: 20px;
      span {
        color: #551a8b;
        padding-right: 8px;
        float: left;
      }
      b {
        float: left;
      }
      .checkUl {
        float: left;
        padding-left: 8px;
        li {
          padding: 0 5px;
          height: 20px;
          line-height: 18px;
          border: 1px solid #ff9999;
          color: #ff9999;
          font-size: 12px;
          background: #fff;
          margin-right: 8px;
          float: left;
        }
      }
    }
  }
  .sortBox {
    box-sizing: content-box;
    background-color: #ffffff;
    margin-bottom: 17px;
    border: 1px solid #eeeeee;
    width: 100%;
    float: left;
    .sortUlbox:last-child {
      border-bottom: none;
    }
    .sortUlbox {
      width: 100%;
      padding: 8px 15px;
      float: left;
      line-height: 40px;
      border-bottom: 1px dashed #ccc;
      .ulTitle {
        font-size: 14px;
        font-weight: bold;
        color: #666666;
        width: 136px;
        text-align: center;
        float: left;
      }
      .sortUl {
        width: calc(100% - 136px);
        float: left;
        li {
          float: left;
          margin-right: 40px;
          color: #666;
          font-size: 14px;
            cursor: pointer;
            &:hover{
                color: #ff3333;
            }
        }
        li.active {
          color: #ff3333;
        }
      }
    }
  }

  .filterUl {
    padding: 8px 15px;
    margin-bottom: 17px;
    border: 1px solid #eeeeee;
    box-sizing: content-box;
    background-color: #ffffff;
    height: 20px;
    line-height: 20px;
    width: 100%;
    float: left;
    li.active {
      color: #ff9999;
    }
    li {
      float: left;
      margin-right: 40px;
      color: #666666;
      font-size: 12px;
      position: relative;
        cursor: pointer;
            &:hover{
                color: #ff3333;
                .el-checkbox{
                  color: #ff3333;
                }
               
            }
      i {
        font-weight: bolder;
        font-size: 14px;
         color: #666666;
        &.active{
           color: #ff3333;
        }
        &.iconTop{
            position:absolute;
            right: -18px;
            top: 0;
        }
        &.iconBottom{
            position:absolute;
            right: -18px;
            bottom: 0;
        }
      }
    }
  }
  .goodsList {
    height: auto;
    // overflow: hidden;
    float: left;
    min-height: 228px;
    background: #f6f6f6;
    width: 100%;
    .goods:nth-child(5n + 0) {
      margin-right: 0px;
    }
    .NullBox{
      font-size: 30px;
      text-align: center;
      width: 100%;
      line-height: 300px;
    height: 300px;
      background:url("data:image/svg+xml,%3Csvg width='130' height='130' xmlns='http://www.w3.org/2000/svg'%3E%3Ctext x='50%25' y='50%25' font-size='14' fill='%23a2a9b6' font-family='system-ui, sans-serif' text-anchor='middle' transform='rotate(-45, 100 100)' dominant-baseline='middle'%3E复盛公%3C/text%3E%3C/svg%3E");
      }
  }
  .clear{
    clear: both;
  }
  .msgDes{
    width: 100%;
    color: #666666;
    line-height:60px;
    font-weight: bold;
    text-align: center;
  }
}
</style>
